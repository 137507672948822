<template>
  <div>
    <div class="row mt-3 mb-sm-3">
      <div class="col-12">
        <div class="form-inline">

          <div class="col d-none d-sm-block"></div>

          <sgv-input-select
            class=""
            placeholder="เลือกเครื่องพิมพ์..."
            :options="printers"
            select="id"
            v-model="deviceId">
            <template slot-scope="option">
              {{option.name}}
            </template>
          </sgv-input-select>

          <button
            v-if="canPrint"
            type="button"
            class="btn btn-warning ml-2 mb-3 mb-sm-0"
            @click="printPaper">
            <fa icon="print" class="d-sm-none"/>
            <span class="d-none d-sm-block">
              <fa icon="print"/>
              <span class="ml-1">พิมพ์</span>
            </span>
          </button>

          <button
            v-if="canPrint"
            type="button"
            class="btn btn-success ml-2 mb-3 mb-sm-0"
            @click="savePaper">
            <fa icon="save" class="d-sm-none"/>
            <span class="d-none d-sm-block">
              <fa icon="save"/>
              <span class="ml-1">บันทึก</span>
            </span>
          </button>

          <button
            v-if="canPrint"
            type="button"
            class="btn btn-info ml-2 mb-3 mb-sm-0"
            @click="downloadPaper">
            <fa icon="download" class="d-sm-none"/>
            <span class="d-none d-sm-block">
              <fa icon="download"/>
              <span class="ml-1">download</span>
            </span>
          </button>
        </div>
      </div>
    </div>

    <div class="row justify-content-center" v-if="pdf">
      <div class="col-12">
        <sgv-pdf :file="pdf"></sgv-pdf>
      </div>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import axios from 'axios'
import debounce from 'lodash/debounce'
import { LIST_PRINTER } from '../graph/print'
import { CREATE_ATTACHMENT } from '../graph/attachment'


export default {
  mixins: [retainMixin],
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    filename: {
      type: String,
      required: true
    },
    canPrint: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      pdf: null,
      deviceId: null,
      printers: [],
      rKey: `Doc${this.$form.capitalize(this.docType)}Print`,
      rFields: ['deviceId'],
    }
  },
  apollo: {
    printers: {
      query () {
        return LIST_PRINTER(this.templateType)
      },
      variables () {
        return {
          docType: this.docType
        }
      }
    }
  },
  methods: {
    debounceSample: debounce(vm => {
      vm.getSample()
    }, 1000),
    getSample () {
      this.getData()
      .then(res => {
        this.pdf = res.data
      }).catch(() => {
        this.getData(null, 'json')
        .catch(err => {
          this.$toasted.global.error(err)
        })
        this.pdf = null
      })
    },
    getData (deviceId, responseType='blob') {
      return this.$axios.get(this.url, {
        responseType,
        params: {deviceId}
      })
    },
    savePaper () {
      this.getData()
      .then(res => this.upload(res.data, this.filename))
      .then(() => {
        this.$toasted.global.success("บันทึกสำเร็จ")
      })
      .catch(() => {
        this.$toasted.global.error("ไม่สามารถอัพโหลดได้")
      })
    },
    downloadPaper () {
      this.getData()
      .then(res => {
        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(res.data)
        a.download = this.filename
        a.click()
      })
      .catch(() => {
        this.$toasted.global.error("ไม่สามารถโหลดได้")
      })
    },
    printPaper () {
      if (!this.deviceId) {
        this.$toasted.global.error("โปรดเลือกเครื่องพิมพ์")
        return
      }

      this.getData(this.deviceId)
      .then(() => {
        this.$toasted.global.success("ส่งไปยังเครื่องพิมพ์")
      })
      .catch(() => {
        this.$toasted.global.error("ไม่สามารถพิมพ์หรือบันทึกไฟล์ได้")
      })
    },
    upload (file, filename) {
      this.$apollo.mutate({
        mutation: CREATE_ATTACHMENT(this.templateType),
        variables: {
          docType: this.docType,
          id: this.docId,
          filename
        }
      })
      .then(res => {
        const config = res.data.createItem
        let formData = new FormData()

        formData.append('acl', 'private')
        formData.append('Content-Type', file.type)
        Object.keys(config.fields).forEach(k => {
          formData.append(k, config.fields[k])
        })
        formData.append('file', file, filename);

        return axios.post(config.url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      })
    },
  },
  watch: {
    docId: {
      handler () {
        this.debounceSample(this)
      },
      immediate: true
    },
    formData: {
      handler () {
        this.debounceSample(this)
      },
      deep: true
    },
    url: {
      handler () {
        this.debounceSample(this)
      },
    },
    deviceId: 'setRetaining'
  },
}
</script>

<style lang="css" scoped>
</style>
