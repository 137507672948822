<template>
  <div class="">
    <div class="form-row">
      <DetailOrderAccountInputAccount
        ref="account"
        label="บัญชี"
        placeholder="เลือกบัญชี..."
        class="col-12"
        select="id"
        :docType="docType"
        :templateType="templateType"
        v-model="formData.accountId"
        :validations="[
          {text: 'required!', value: $v.formData.accountId.$dirty && $v.formData.accountId.$error}
        ]">
      </DetailOrderAccountInputAccount>

      <DetailOrderAccountInputTag
        label="ประเภท"
        placeholder="ทั่วไป"
        class="col-6 col-md-4"
        :docType="docType"
        :templateType="templateType"
        v-model="formData.tag">
      </DetailOrderAccountInputTag>

      <sgv-input-number
        label="เดบิต/เครดิต(-)"
        class="col-6 col-md-4"
        :precision="2"
        minus
        placeholder="[+] Dr, [-] Cr"
        v-model="formData.price"
        :validations="[
          {text: 'required!', value: $v.formData.price.$dirty && !$v.formData.price.required}
        ]"
        @keyup.enter.native="createOrder">
      </sgv-input-number>

      <sgv-input-text
        label="parentId (ใช้ในกรณีล้างหนี้)"
        class="col-6 col-md-4"
        v-model="formData.parentId">
      </sgv-input-text>
    </div>

    <div class="form-group">
      <button class="btn btn-info" @click="createOrder">
        บัญชี
      </button>
    </div>
  </div>

</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailOrderAccountInputAccount from './DetailOrderAccountInputAccount.vue'
import DetailOrderAccountInputTag from './DetailOrderAccountInputTag.vue'
import { CREATE_ORDER_ACCOUNT } from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formData: {
        tag: null,
        accountId: null,
        price: 0,
        parentId: 0
      }
    }
  },
  validations: {
    formData: {
      accountId: { required },
      price: { required },
    }
  },
  methods: {
    serializeInput () {
      return {
        tag: this.formData.tag || '',
        accountId: this.formData.accountId || '',
        price: this.formData.price || 0,
        parentId: +this.formData.parentId || 0
      }
    },
    createOrder () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_ORDER_ACCOUNT(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input: this.serializeInput()
        }
      })
      .then(res => {
        this.$emit('orderCreated', res.data.order)
        this.setDefault()
        this.$refs.account.setFocus()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    setDefault () {
      this.formData.tag = null
      this.formData.accountId = null
      this.formData.price = 0
      this.formData.parentId = 0
      this.$v.formData.$reset()
    }
  },
  watch: {
    'formData.accountId': {
      handler (v) {
        if (!v) {
          this.formData.price = 0
          this.formData.parentId = 0
          this.$v.formData.$reset()
        }
      }
    }
  },
  created () {
    this.setDefault()
  },
  components: {
    DetailOrderAccountInputTag,
    DetailOrderAccountInputAccount,
  }
}
</script>

<style lang="css" scoped>
</style>
