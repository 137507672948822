import gql from 'graphql-tag'


export const LIST_DOC_DROPDOWN_REPAIR = (templateType) => gql`query LIST_DOC_DROPDOWN_REPAIR ($docType: String!, $docId: Int!, $q: FilterInput) {
  docs: list${templateType}DocDropdownRepair (docType: $docType, docId: $docId, q: $q) {
    id type code name remark
  }
}`

export const CREATE_DOC_REPAIR = (templateType) => gql`mutation CREATE_DOC_REPAIR ($docType: String!, $docId: Int!, $repairId: Int!) {
  createDoc: create${templateType}DocRepair (docType: $docType, docId: $docId, repairId: $repairId) {
    id parentId childId
  }
}`

export const DESTROY_DOC_REPAIR = (templateType) => gql`mutation DESTROY_DOC_REPAIR ($docType: String!, $docId: Int!, $repairId: Int!) {
  destroyDoc: destroy${templateType}DocRepair (docType: $docType, docId: $docId, repairId: $repairId) {
    id parentId childId
  }
}`
