<template>
  <div class="form-row" v-if="isShow">
    <div class="col-12">
      <sgv-input-check-array
        :disabled="disabled && !toggle"
        :options="items"
        inline
        :value="value"
        select="id"
        @input="emitInput">
        <legend slot="label" class="col-form-label pt-0">
          หมวดหมู่
          <span
            v-if="isClosed && !toggle"
            class="text-warning px-1 pointer"
            @click="toggle = !toggle">
            <fa icon="pencil-alt"></fa>
          </span>

          <span
            v-if="toggle"
            class="text-success px-1 pointer"
            @click="syncData">
            <fa icon="check"></fa>
          </span>
        </legend>

        <template slot-scope="option">
          <span :class="{'text-success': value.find(v => v.id === option.id)}">
            {{option.name}}
          </span>
        </template>
      </sgv-input-check-array>
    </div>
  </div>
</template>

<script>
import {
  LIST_CATEGORY,
  SYNC_CATEGORY
} from '../graph/category'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    method: {
      type: String,
      required: true
    },
    isClosed: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      categories: [],
      toggle: false
    }
  },
  apollo: {
    categories: {
      query () {
        return LIST_CATEGORY(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          q: {order: 'code'}
        }
      },
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    items () {
      if (['edit', 'add'].includes(this.method) || this.toggle) {
        return this.categories
      } else {
        return this.categories.filter(v => this.value.includes(v.id))
      }
    },
    isShow () {
      const isEdit = this.value.length > 0 || ['edit', 'add'].includes(this.method)
      return this.categories.length > 0 && (isEdit || this.isClosed)
    }
  },
  methods: {
    emitInput (v) {
      this.$emit('input', v)
    },
    syncData () {
      if (this.docId === 0) return

      this.$apollo.mutate({
        mutation: SYNC_CATEGORY(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          categories: this.value
        }
      })
      .then(() => {
        this.$emit('updated', null)
        this.toggle = false
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  }
}
</script>

<style lang="css">
</style>
