<template>
  <div class="mb-3">
    <div class="mb-1">
      {{label}}
    </div>

    <div class="card">
      <div class="card-body">
        <div v-if="method === 'edit'">
          <sgv-input-text v-model="search" label="ค้นหา"></sgv-input-text>
          <ul class="list-unstyled">
            <li v-for="doc in items" :key="doc.id">
              <span
                class="text-success pointer pr-1"
                @click="addDoc(doc)">
                <fa icon="plus"></fa>
              </span>

              {{doc.code}}
              <span v-show="doc.name">
                ({{doc.name}})
              </span>
            </li>
          </ul>
        </div>

        <ul
          class="list-unstyled mb-0"
          v-if="formData.repairs.length > 0">
          <li v-for="repair in formData.repairs" :key="repair.id" class="my-1">
            <span
              v-if="method === 'edit'"
              class="text-danger pointer pr-1"
              @click="deleteDoc(repair.id)">
              <fa icon="trash"></fa>
            </span>
            <router-link
              :to="toDoc(repair)"
              class="text-decoration-none">
              <span style="white-space: nowrap;">
                {{repair.code}}
              </span>
            </router-link>
            <div>
              <small class="text-primary">
                {{repair.name}}
              </small>
            </div>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import {
  LIST_DOC_DROPDOWN_REPAIR,
  CREATE_DOC_REPAIR,
  DESTROY_DOC_REPAIR
} from '../graph/repair'

export default {
  name: 'DetailFormRepairInput',
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      items: [],
      search: ''
    }
  },
  methods: {
    getList: debounce(function (search='') {
      this.$apollo.query({
        query: LIST_DOC_DROPDOWN_REPAIR(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          q: {
            limit: 5,
            params: {search},
            order: 'code'
          }
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.items = res.data.docs
      })
      .catch((err) => {
        this.$toasted.global.error(err)
        this.items = []
      })
    }, 150),
    addDoc (doc) {
      this.$apollo.mutate({
        mutation: CREATE_DOC_REPAIR(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          repairId: doc.id
        }
      })
      .then(() => {
        this.addDocs(doc)
        this.getList(this.search)
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    deleteDoc (repairId) {
      this.$apollo.mutate({
        mutation: DESTROY_DOC_REPAIR(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          repairId
        }
      })
      .then(() => {
        this.removeDocs(repairId)
        this.getList(this.search)
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    addDocs (doc) {
      const index = this.formData.repairs.findIndex(v => v.id === doc.id)
      if (index === -1) this.formData.repairs.unshift(doc)
    },
    removeDocs (docId) {
      const index = this.formData.repairs.findIndex(v => v.id === docId)
      this.formData.repairs.splice(index, 1)
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id}
      }
    },
  },
  watch: {
    search (value) {
      this.getList(value)
    },
    method (value) {
      if (value === 'edit') {
        this.getList(this.search)
      }
    }
  }
}
</script>

<style lang="css">
</style>
