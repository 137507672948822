import { render, staticRenderFns } from "./PrintPdf.vue?vue&type=template&id=48f31c81&scoped=true&"
import script from "./PrintPdf.vue?vue&type=script&lang=js&"
export * from "./PrintPdf.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f31c81",
  null
  
)

export default component.exports