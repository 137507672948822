<template>
  <div class="card">
    <div class="card-body pb-1">
      <div class="mb-3 text-right">
        เดบิต:
        <span class="text-success mr-3">
          {{debitAmount | comma}}
        </span>

        เครดิต:
        <span class="text-danger">
          {{creditAmount | comma}}
        </span>
      </div>

      <sgv-table
        headerless
        :items="accounts"
        :headers="headers"
        :options.sync="options">

        <template slot-scope="{item, hidden}">
          <tr>
            <td v-if="hidden.code" class="no-wrap">
              {{item.code}}
            </td>
            <td v-if="hidden.name">
              {{item.name}}
            </td>
            <td v-if="hidden.debit" class="text-success text-right">
              <span v-if="item.debit > 0">{{item.debit | comma}}</span>
              <span v-else>-</span>
            </td>
            <td v-if="hidden.credit" class="text-danger text-right">
              <span v-if="item.credit > 0">{{item.credit | comma}}</span>
              <span v-else>-</span>
            </td>
          </tr>
        </template>
      </sgv-table>
    </div>
  </div>
</template>

<script>
import { LIST_LEDGER_ACCOUNT } from '../graph/ledger'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      headers: [
        {text: 'รหัส', value: 'code'},
        {text: 'ชื่อ', value: 'name'},
        {text: 'เดบิต', value: 'debit'},
        {text: 'เครดิต', value: 'credit'},
      ],
      options: {
        headers: ['code', 'name', 'debit', 'credit'],
        column: null,
        search: null,
        toolbar: null,
      },
      accounts: []
    }
  },
  apollo: {
    accounts: {
      query () {
        return LIST_LEDGER_ACCOUNT(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  computed: {
    debitAmount () {
      return this.accounts.reduce((t,v) => t += +v.debit, 0)
    },
    creditAmount () {
      return this.accounts.reduce((t,v) => t += +v.credit, 0)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
