var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('DetailOrderOutItemInputInventory',{ref:"dropdownInventory",staticClass:"col-12",attrs:{"label":"เบิกสินค้า","placeholder":"สินค้า...","select":"id","docType":_vm.docType,"templateType":_vm.templateType,"validations":[
        {text: 'required!', value: _vm.$v.formData.inventoryId.$dirty && _vm.$v.formData.inventoryId.$error}
      ]},model:{value:(_vm.formData.inventoryId),callback:function ($$v) {_vm.$set(_vm.formData, "inventoryId", $$v)},expression:"formData.inventoryId"}}),_c('sgv-input-number',{staticClass:"col-6 col-sm-3",attrs:{"label":"จำนวน","precision":2,"placeholder":"จำนวน","validations":[
        {text: 'required!', value: _vm.$v.formData.qty.$dirty && !_vm.$v.formData.qty.required},
        {text: 'จำนวนมากกว่า 0', value: _vm.$v.formData.qty.$dirty && !_vm.$v.formData.qty.minValue}
      ]},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createOrder.apply(null, arguments)}},model:{value:(_vm.formData.qty),callback:function ($$v) {_vm.$set(_vm.formData, "qty", $$v)},expression:"formData.qty"}}),_c('sgv-input-text',{staticClass:"col-6 col-sm-3",attrs:{"label":"Stock ID","placeholder":"Stock ID","validations":[
        {text: 'required!', value: _vm.$v.formData.stockId.$dirty && _vm.$v.formData.stockId.$error}
      ]},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createOrder.apply(null, arguments)}},model:{value:(_vm.formData.stockId),callback:function ($$v) {_vm.$set(_vm.formData, "stockId", $$v)},expression:"formData.stockId"}})],1),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-danger",on:{"click":_vm.createOrder}},[_vm._v(" เบิกสินค้า ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }