<template>
  <div class="">
    <div class="form-row">
      <DetailOrderOutItemInputInventory
        label="เบิกสินค้า"
        ref="dropdownInventory"
        placeholder="สินค้า..."
        class="col-12"
        select="id"
        :docType="docType"
        :templateType="templateType"
        v-model="formData.inventoryId"
        :validations="[
          {text: 'required!', value: $v.formData.inventoryId.$dirty && $v.formData.inventoryId.$error}
        ]">
      </DetailOrderOutItemInputInventory>

      <sgv-input-number
        label="จำนวน"
        class="col-6 col-sm-3"
        :precision="2"
        placeholder="จำนวน"
        v-model="formData.qty"
        :validations="[
          {text: 'required!', value: $v.formData.qty.$dirty && !$v.formData.qty.required},
          {text: 'จำนวนมากกว่า 0', value: $v.formData.qty.$dirty && !$v.formData.qty.minValue}
        ]"
        @keyup.enter.native="createOrder">
      </sgv-input-number>

      <sgv-input-text
        label="Stock ID"
        class="col-6 col-sm-3"
        placeholder="Stock ID"
        v-model="formData.stockId"
        :validations="[
          {text: 'required!', value: $v.formData.stockId.$dirty && $v.formData.stockId.$error}
        ]"
        @keyup.enter.native="createOrder">
      </sgv-input-text>
    </div>

    <div class="form-group">
      <button class="btn btn-danger" @click="createOrder">
        เบิกสินค้า
      </button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailOrderOutItemInputInventory from './DetailOrderOutItemInputInventory.vue'
import { CREATE_ORDER_OUT_ITEM } from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formData: {
        inventoryId: null,
        qty: 0,
        stockId: ''
      }
    }
  },
  validations () {
    const formData = {
      inventoryId: {required},
      qty: {
        required,
        minValue: (value) => value > 0
      },
      stockId: {required}
    }
    return {formData}
  },
  methods: {
    serializeInput (input) {
      const formData = {
        inventoryId: input.inventoryId,
        qty: input.qty,
        stockId: input.stockId.split(' ').map(v => +v)
      }

      return formData
    },
    createOrder () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: CREATE_ORDER_OUT_ITEM(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input
        }
      })
      .then(res => {
        this.$emit('orderCreated', res.data.createOrder)
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    setDefault () {
      this.formData.docConfigId = null
      this.formData.qty = 0
      this.formData.stockId = null
      this.$v.formData.$reset()
    }
  },
  watch: {
    'formData.inventoryId': {
      handler () {
        this.formData.qty = 0
        this.formData.stockId = null
        this.$v.formData.$reset()
      }
    }
  },
  created () {
    this.setDefault()
  },
  components: {
    DetailOrderOutItemInputInventory
  }
}
</script>

<style lang="css" scoped>
</style>
