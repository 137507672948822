import gql from 'graphql-tag'


const listResponse = `
  id type code name remark migrationNote invoiceRef
  contactId contact {id type code name branch}
  createdAt createdBy createdUser {id name}
  approvedAt approvedBy approvedUser {id name}
  closedAt closedBy closedUser {id name}
  repairs {id type code name}
  categories {id name}
`

export const LIST_DOC = (templateType) => gql`query LIST_DOC ($docType: String!, $q: FilterInput) {
  docs: list${templateType}Doc (docType: $docType, q: $q) {${listResponse}}
}`

export const WATCH_DOCS_CREATED = (templateType) => gql`subscription WATCH_DOCS_CREATED ($docType: String!) {
  docCreated: watch${templateType}DocCreated (docType: $docType) {${listResponse}}
}`

export const WATCH_DOCS_UPDATED = (templateType) => gql`subscription WATCH_DOCS_UPDATED ($docType: String!) {
  docUpdated: watch${templateType}DocUpdated (docType: $docType) {${listResponse}}
}`

export const WATCH_DOCS_DESTROYED = (templateType) => gql`subscription WATCH_DOCS_DESTROYED ($docType: String!) {
  docDestroyed: watch${templateType}DocDestroyed (docType: $docType) {id}
}`

const detailResponse = `
  id type code name remark invoiceRef
  contactId contact {id type code name branch}
  createdAt createdBy createdUser {id name}
  approvedAt approvedBy approvedUser {id name}
  closedAt closedBy closedUser {id name}
  repairs {id type code name}
  categories {id name}
`

export const DETAIL_DOC = (templateType) => gql`query DOC ($docType: String!, $docId: Int!) {
  doc: detail${templateType}Doc (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_DOC_UPDATED = (templateType) => gql`subscription WATCH_DOC_UPDATED ($docType: String!, $docId: Int) {
  docUpdated: watch${templateType}DocUpdated (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const CREATE_DOC = (templateType) => gql`mutation CREATE_DOC ($docType: String!, $input: ${templateType}DocCreateInput!) {
  doc: create${templateType}Doc (docType: $docType, input: $input) {id}
}`

export const UPDATE_DOC = (templateType) => gql`mutation UPDATE_DOC ($docType: String!, $docId: Int!, $input: ${templateType}DocUpdateInput!) {
  doc: update${templateType}Doc (docType: $docType, docId: $docId, input: $input) {id}
}`

export const DESTROY_DOC = (templateType) => gql`mutation DESTROY_DOC ($docType: String!, $docId: Int!) {
  doc: destroy${templateType}Doc (docType: $docType, docId: $docId) {id}
}`

export const APPROVE_DOC = (templateType) => gql`mutation APPROVE_DOC ($docType: String!, $docId: Int!) {
  doc: approve${templateType}Doc (docType: $docType, docId: $docId) {id}
}`

export const CANCEL_DOC = (templateType) => gql`mutation CANCEL_DOC ($docType: String!, $docId: Int!) {
  doc: cancel${templateType}Doc (docType: $docType, docId: $docId) {id}
}`

export const CLOSE_DOC = (templateType) => gql`mutation CLOSE_DOC ($docType: String!, $docId: Int!, $closedDate: Date!) {
  doc: close${templateType}Doc (docType: $docType, docId: $docId, closedDate: $closedDate) {id}
}`

export const ROLLBACK_DOC = (templateType) => gql`mutation ROLLBACK_DOC ($docType: String!, $docId: Int!) {
  doc: rollback${templateType}Doc (docType: $docType, docId: $docId) {id}
}`

export const LIST_DOC_DROPDOWN_CONTACT = (templateType) => gql`query LIST_DOC_DROPDOWN_CONTACT ($docType: String!, $q: FilterInput) {
  items: list${templateType}DocDropdownContact (docType: $docType, q: $q) {
    id type code name branch
  }
}`
