import gql from 'graphql-tag'


export const LIST_ATTACHMENT = (templateType) => gql`query LIST_ATTACHMENT ($docType: String!, $id: Int!) {
  items: list${templateType}Attachment (docType: $docType, id: $id)
}`

export const DETAIL_ATTACHMENT = (templateType) => gql`query DETAIL_ATTACHMENT ($docType: String!, $id: Int!, $filename: String!) {
  url: detail${templateType}Attachment (docType: $docType, id: $id, filename: $filename)
}`

export const CREATE_ATTACHMENT = (templateType) => gql`mutation CREATE_ATTACHMENT ($docType: String!, $id: Int!, $filename: String!) {
  createItem: create${templateType}Attachment (docType: $docType, id: $id, filename: $filename)
}`

export const DESTROY_ATTACHMENT = (templateType) => gql`mutation DESTROY_ATTACHMENT ($docType: String!, $id: Int!, $filename: String!) {
  deleteItem: destroy${templateType}Attachment (docType: $docType, id: $id, filename: $filename)
}`
