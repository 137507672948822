import gql from 'graphql-tag'


const detailResponse = `
  id type name parentId
  qty price totalPrice
  unitId unit {id name}
  stocks {
    id qty docId parentId orderId isPrinted
    inventoryId inventory {
      id code name
      unitId unit {id name}
    }
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  ledgers {
    id docId stockId orderId amount
    accountId account {id code name}
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  parent {
    id docId doc {id type code}
  }
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: list${templateType}Order (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  order: destroy${templateType}Order (docType: $docType, orderId: $orderId) {id docId}
}`

export const CREATE_ORDER_OUT_ITEM = (templateType) => gql`mutation CREATE_ORDER_OUT_ITEM ($docType: String!, $docId: Int!, $input: ${templateType}OrderOutItemInput!) {
  order: create${templateType}OrderOutItem (docType: $docType, docId: $docId, input: $input) {${detailResponse}}
}`

export const CREATE_ORDER_IN_ITEM = (templateType) => gql`mutation CREATE_ORDER_IN_ITEM ($docType: String!, $docId: Int!, $input: ${templateType}OrderInItemInput!) {
  order: create${templateType}OrderInItem (docType: $docType, docId: $docId, input: $input) {${detailResponse}}
}`

export const CREATE_ORDER_ACCOUNT = (templateType) => gql`mutation CREATE_ORDER_ACCOUNT ($docType: String!, $docId: Int!, $input: ${templateType}OrderAccountInput!) {
  order: create${templateType}OrderAccount (docType: $docType, docId: $docId, input: $input) {${detailResponse}}
}`

export const CREATE_ORDER_IMPORT_ITEM = (templateType) => gql`mutation CREATE_ORDER_IMPORT_ITEM ($docType: String!, $docId: Int!, $input: [${templateType}OrderImportItemInput]!) {
  orders: create${templateType}OrderImportItem (docType: $docType, docId: $docId, input: $input) {${detailResponse}}
}`

export const LIST_ORDER_DROPDOWN_INVENTORY_OUT_ITEM = (templateType) => gql`query LIST_ORDER_DROPDOWN_INVENTORY_OUT_ITEM ($docType: String!, $q: FilterInput) {
  items: list${templateType}OrderDropdownInventoryOutItem (docType: $docType, q: $q) {
    id type code name
  }
}`

export const LIST_ORDER_DROPDOWN_INVENTORY_IN_ITEM = (templateType) => gql`query LIST_ORDER_DROPDOWN_INVENTORY_IN_ITEM ($docType: String!, $q: FilterInput) {
  items: list${templateType}OrderDropdownInventoryInItem (docType: $docType, q: $q) {
    id type code name
  }
}`

export const LIST_ORDER_DROPDOWN_ACCOUNT = (templateType) => gql`query LIST_ORDER_DROPDOWN_ACCOUNT ($docType: String!, $q: FilterInput) {
  items: list${templateType}OrderDropdownAccount (docType: $docType, q: $q) {
    id code name
  }
}`

export const LIST_ORDER_DROPDOWN_TAG = (templateType) => gql`query LIST_ORDER_DROPDOWN_TAG ($docType: String!) {
  items: list${templateType}OrderDropdownTag (docType: $docType) {
    text value
  }
}`
