<template>
  <div class="">
    <div class="form-row">
      <DetailOrderInItemInputInventory
        label="นำเข้าสินค้า"
        placeholder="สินค้า..."
        class="col-12"
        select="id"
        :docType="docType"
        :templateType="templateType"
        v-model="formData.inventoryId"
        :validations="[
          {text: 'required!', value: $v.formData.inventoryId.$dirty && $v.formData.inventoryId.$error}
        ]">
      </DetailOrderInItemInputInventory>

      <sgv-input-number
        label="จำนวน"
        class="col-6 col-sm-3"
        :precision="2"
        placeholder="จำนวน"
        v-model="formData.qty"
        :validations="[
          {text: 'required!', value: $v.formData.qty.$dirty && !$v.formData.qty.required},
          {text: 'จำนวนมากกว่า 0', value: $v.formData.qty.$dirty && !$v.formData.qty.minValue}
        ]"
        @keyup.enter.native="createOrder">
      </sgv-input-number>

      <sgv-input-number
        label="เดบิต(Dr)"
        class="col-6 col-sm-3"
        :precision="2"
        placeholder="มูลค่าทั้งสิ้น"
        v-model="formData.price"
        :validations="[
          {text: 'required!', value: $v.formData.price.$dirty && !$v.formData.price.required},
          {text: 'จำนวนมากกว่า 0', value: $v.formData.price.$dirty && !$v.formData.price.minValue}
        ]"
        @keyup.enter.native="createOrder">
      </sgv-input-number>

      <DetailOrderAccountInputAccount
        ref="account"
        label="บัญชี(กรณีผูกบัญชีสินค้า)"
        placeholder="บัญชี..."
        class="col-12 col-sm-6"
        select="id"
        :docType="docType"
        :templateType="templateType"
        v-model="formData.accountId">
      </DetailOrderAccountInputAccount>
    </div>

    <div class="form-group">
      <button class="btn btn-success" @click="createOrder">
        นำเข้าสินค้า
      </button>
    </div>
  </div>

</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailOrderInItemInputInventory from './DetailOrderInItemInputInventory.vue'
import DetailOrderAccountInputAccount from './DetailOrderAccountInputAccount.vue'
import { CREATE_ORDER_IN_ITEM } from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formData: {
        inventoryId: null,
        accountId: null,
        price: 0,
        qty: 0
      }
    }
  },
  validations: {
    formData: {
      inventoryId: { required },
      qty: {
        required,
        minValue: (value) => value > 0
      },
      price: {
        required,
        minValue: (value) => value >= 0
      },
    }
  },
  methods: {
    createOrder () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_ORDER_IN_ITEM(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input: this.formData
        }
      })
      .then(res => {
        this.$emit('orderCreated', res.data.order)
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    setDefault () {
      this.formData.inventoryId = null
      this.formData.accountId = null
      this.formData.price = 0
      this.formData.qty = 0
      this.$v.formData.$reset()
    }
  },
  watch: {
    'formData.inventoryId': {
      handler (v) {
        if (!v) {
          this.formData.accountId = null
          this.formData.price = 0
          this.formData.qty = 0
          this.$v.formData.$reset()
        }
      }
    }
  },
  created () {
    this.setDefault()
  },
  components: {
    DetailOrderInItemInputInventory,
    DetailOrderAccountInputAccount
  }
}
</script>

<style lang="css" scoped>
</style>
