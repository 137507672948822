<template>
  <div class="">
    <div class="form-row">
      <sgv-input-file
        class="col-12"
        ref="fileUpload"
        label="ไฟล์ CSV"
        @change="changeToJSON($event)">
      </sgv-input-file>
    </div>

    <div class="" v-if="items.length > 0">
      <ol>
        <li v-for="(item,idx) in items" :key="idx">
          <span>{{item.inventoryCode}}</span>
          <span class="px-2">จำนวน: {{item.qty}}</span>
          <span>ราคา: {{item.price}}</span>
          <span v-if="item.accountCode" class="px-2">
            บัญชี: {{item.accountCode}}
          </span>
        </li>
      </ol>
    </div>

    <sgv-csv
      :items="csvData"
      :labels="csvLabels"
      :filename="csvFilename">
      <button
        type="button"
        class="btn btn-info mb-3 mr-2">
        ตัวอย่าง CSV
      </button>
    </sgv-csv>

    <button
      :disabled="isImporting"
      v-if="items.length > 0"
      type="button"
      class="btn btn-success mb-3"
      @dblclick="importData">
      เพิ่มทั้งหมด
    </button>
  </div>
</template>

<script>
import { CREATE_ORDER_IMPORT_ITEM } from './graph'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      isImporting: false,
      items: [],
      csvFilename: 'ตัวอย่างนำเข้าสินค้า',
      csvLabels: {
        inventoryCode: { title: 'inventoryCode' },
        qty: { title: 'qty' },
        price: { title: 'price' },
        accountCode: { title: 'accountCode' },
      },
      csvData: [
        {
          inventoryCode: 'รหัสสินค้า',
          qty: 'จำนวน',
          price: 'ราคาสุทธิ',
          accountCode: 'บัญชี(perpetual)',
        },
        {
          inventoryCode: 'A01',
          qty: '10',
          price: '100',
          accountCode: '',
        },
        {
          inventoryCode: 'B01',
          qty: '5',
          price: '200',
          accountCode: '',
        },
      ],
    }
  },
  methods: {
    changeToJSON (e) {
      var fileInput = e.target.files[0]
      const options = {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          this.items = this.serializeInput(results.data.slice(1))
        }
      }
      this.$papa.parse(fileInput, options)
    },
    serializeInput (input) {
      return input.map(v => {
        return {
          inventoryCode: v.inventoryCode,
          accountCode: v.accountCode,
          qty: +v.qty,
          price: +v.price
        }
      })
    },
    importData () {
      this.isImporting = true

      this.$apollo.mutate({
        mutation: CREATE_ORDER_IMPORT_ITEM(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input: this.items
        }
      })
      .then(() => {
        this.$emit('updated', null)
        this.$toasted.global.success('นำเข้าสำเร็จ')
      })
      .catch(err => {
        this.isImporting = false
        this.$toasted.global.error(err)
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
